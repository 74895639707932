import { Component , OnInit} from '@angular/core';
import { AuthenticationService2 } from './core/services/auth2.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {
  username: string;

  constructor(
    protected authService: AuthenticationService2) {}

  ngOnInit() {
    document.body.setAttribute('data-layout', '');
    document.body.setAttribute('data-sidebar', 'dark');

    this.authService.getAuthenticatedUser();
    this.username = this.authService.username;

  }
}
