<div id="layout-wrapper">
  <ng-container *ngIf="( authService.isAuthenticated$ | async) === true">
    <app-topbar #topbar [username]="username"> </app-topbar>
  
    <app-sidebar
      (menuButtonClicked)="topbar.toggleMobileMenu()"
      [isCondensed]="(topbar.showSideBar$ | async) === true"
    >
    </app-sidebar>
  </ng-container>
  
    <div class="main-content">
      <div class="page-content">
        <!-- content -->
        <router-outlet></router-outlet>
      </div>
  
      <!-- footer -->
      <app-footer *ngIf="( authService.isAuthenticated$ | async) === true"></app-footer>
    </div>
  </div>